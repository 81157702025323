import React, { memo } from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/Header'
import { Container, Layout, LayoutMain, LayoutAside } from '../components/Layout'
import Const from '../const'

export default memo(() => {
  return (
    <Container>
      <Helmet>
        <title>{ Const.SITE_NAME } | Not Found</title>
      </Helmet>

      <Header />

      <Layout>
        <LayoutMain>
          <h1>ページがありません</h1>
        </LayoutMain>

        <LayoutAside />
      </Layout>
    </Container>
  )
})
